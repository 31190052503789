import React, { useEffect, useState } from "react";

import linegoldicon from './../static/linegoldicon.png'

import Navbar from "./../components/navbar";
import axios from "axios";
import menu from './../static/hero2-2.jpg'
import { MenuEnglish,MenuItalian } from "../components/LanguageTexts";
import {Helmet} from "react-helmet";




const Menu=(props)=>{

const[menus,setMenus]=useState({})
const [texts,setTexts]=useState(MenuItalian)

 useEffect(()=>{
  if(props.language==='ita'){
    axios.post('/getmenu')
    .then((res)=>{setMenus(res.data.data)})
    .catch(err=> {})
  }
  else if(props.language==='eng'){
    axios.post('/getENmenu')
    .then((res)=>{setMenus(res.data.data)})
    .catch(err=> {})
  }
  
 },[])
 
 useEffect(()=>{
  if(props.language==='ita'){setTexts(MenuItalian)}
  else if(props.language==='eng'){setTexts(MenuEnglish)}

})
return(
    
    <div className="h-fit w-full bg-[#F1EAED] pb-10 justify-start items-start Lora">
    <Helmet>
                <meta charSet="utf-8" />
                <title>Menù | Osteria il Bargello Siena | antipasti, primi e secondi piatti </title>
                <link rel="canonical" href="https://osteriailbargello.it/menu" />
                <meta name="description" content="Taglieri di salumi e formaggi, pici cacio e pepe e all’aglione, cinghiale, tartare e altre specialità all’Osteria il Bargello. Sfoglia il menù completo!" />
            </Helmet>
     <div className="h-fit max-h-[400px] w-full overflow-hidden flex flex-col items-center justify-start " >
     <Navbar language={props.language} setLang={props.setLang}  />
     <h1 className="absolute top-[10%] md:top-[25%] text-white   md:text-6xl text-5xl  text-center Vidaloka">{texts.title}</h1>

         <img className="w-full  top-0 min-w-[500px] " title="Menù dell’Osteria Il Bargello Siena" alt="Il menù dell’osteria il bargello è composto dai piatti principali della cucina toscana (antipasti, primi e secondi) a cui abbinare un buon calice di vino per concedersi un momento unico a due passi da piazza del campo nel centro storico di Siena." src={menu} />


     </div>
     <div className=" flex flex-col w-full h-fit items-center justify-end  mt-5 px-5 gap-5">
       <p className="text-black md:w-[60%]  w-[80%] text-2xl  text-center ">{texts.paragraph}</p>
       </div>
       
     <div className="w-full h-fit pt-5 flex flex-col lg:grid grid-cols-2 "   >
       <div className="flex flex-col h-fit pt-5 md:col-span-1">
       <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />
        <h2 className="text-black uppercase text-xl">Antipasti</h2>
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />

       </div>
       <div className="flex flex-col justify-center items-center px-10 gap-5" >
       {menus.antipasti && menus.antipasti.map((x)=>
            <div className="flex flex-row flex-nowrap justify-between items-center border-b-2 border-dotted border-gray-500 w-[100%] py-3"> 
              <p className="text-black pr-3">{x.descrizione}</p>
              <p className="font-sans text-black">{Number(x.prezzo.replace(/,/g, '.'))+'€'}</p>

            </div>
        )}
       </div>
    
       </div>
       <div className="flex flex-col h-fit pt-5">
       <div className="flex flex-col h-fit justify-center items-center w-[100%] lg:col-span-2" >
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />
        <h2 className="text-black uppercase text-xl">Primi piatti</h2>
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />

       </div>
       <div className="flex flex-col justify-center items-center px-10 gap-5" >
       {menus.primi && menus.primi.map((x)=>
            <div className=" flex-nowrap flex flex-row justify-between items-center border-b-2 border-dotted border-gray-500 w-[100%] py-3"> 
              <p className="text-black pr-3">{x.descrizione}</p>
              <p className="font-sans text-black">{Number(x.prezzo.replace(/,/g, '.'))+'€'} </p>

            </div>
        )}
       </div>
    
       </div><div className="flex flex-col h-fit pt-5">
       <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />
        <h2 className="text-black uppercase text-xl">Secondi Piatti</h2>
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />

       </div>
       <div className="flex flex-col justify-center items-center px-10 gap-5" >
       {menus.secondi && menus.secondi.map((x)=>
            <div className="   flex flex-row flex-nowrap justify-between items-center border-b-2 border-dotted border-gray-500 w-[100%] py-3"> 
              <p className="text-black pr-3">{x.descrizione}</p>
              <p className="font-sans text-black">{Number(x.prezzo.replace(/,/g, '.'))+'€'} </p>

            </div>
        )}
       </div>
    
       </div>
       <div className="flex flex-col h-fit pt-5">
       <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />
        <h2 className="text-black uppercase text-xl">Dessert</h2>
        <img  className='w-[100%] h-10 px-10' src={linegoldicon} />

       </div>
       <div className="flex flex-col justify-center items-center px-10 gap-5" >
       {menus.dessert && menus.dessert.map((x)=>
            <div className="flex flex-row justify-between items-center border-b-2 border-dotted border-gray-500 w-[100%] py-3"> 
              <p className="text-black pr-3">{x.descrizione}</p>
              <p className="font-sans text-black">{Number(x.prezzo.replace(/,/g, '.'))+'€'} </p>

            </div>
        )}
       </div>
    
       </div>
       

     </div>
    </div> 
   

    
)
};

export default Menu;
