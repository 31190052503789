import React, { useEffect, useState } from "react";
import { Router, Routes, Route, useParams} from 'react-router-dom';
import { UserContext } from "../App.js";
import {Adminlogin, AdminPanel} from "../Pages/administration.jsx";
import Home from "../Pages/home.jsx";
import  Menu from "../Pages/menu.jsx";
import Wines from "../Pages/wines.jsx";
import { useContext } from 'react';
import Events from "../Pages/events.jsx";
import PhotoGallery from "../Pages/gallery.jsx";
import NotFoundPage from "../Pages/notfoundpage.jsx";


const Navigation=()=>{
    const [language,setLanguage]=useState('ita')
    let div=useParams()
    const user=useContext(UserContext)
  const setLang=(lang)=>{
    if(language==='ita'){
      window.localStorage.setItem('language','eng');
      setLanguage('eng')}
    else if(language==='eng'){
      window.localStorage.setItem('language','ita');
      setLanguage('ita')}
  }

  useEffect(()=>{
 if(window.localStorage.getItem('language')==='ita'){setLanguage('ita')}
 else if(window.localStorage.getItem('language')=='eng'){setLanguage('eng')}


  },[])
    return(
  <Routes>
    <Route path="/"  element={<Home language={language} setLang={setLang}  />} />
 
    <Route path="/home" element={<Home language={language} setLang={setLang}    />} />


    <Route path="/menu" element={<Menu  language={language} setLang={setLang}  />} />
    <Route path="/vini" element={<Wines  language={language} setLang={setLang}  />} />
    <Route path="/eventi" element={<Events  language={language} setLang={setLang}  />}  />
    <Route path="/gallery" element={<PhotoGallery  language={language} setLang={setLang}  />}  />


    <Route path="/admin" element={user.isAuth?<AdminPanel />:<Adminlogin />} />
    <Route path="*" element={<NotFoundPage />} />





      


  </Routes>
    )
}


export default Navigation;