

const NavbarIta={
  
    About:'Chi siamo',
    Wines:'Vini',
    Salotto:'Il Salotto del Vino',
    Carta:'La carta dei Vini',
    Events:'Eventi'
}
const NavbarEng={
  
    About:'About us',
    Wines:'Wines',
    Salotto:'Il Bargello Wine Shop',
    Carta:'Wine list',
    Events:'Events'
}


const HomeItalian={
    hero1:{
    
    title:'Wine Bar Osteria a Siena',
    paragraph:'Sospesa tra il Duomo e Piazza del Campo, in una delle vie più antiche ed eleganti del centro storico.',
    paragraph2:`Uno scenario straordinario a cui abbinare i nostri piatti e un buon calice di vino, per regalarsi un momento unico.
     `     },
    chisiamo:{
       title:'Chi siamo',
       paragraph:`Siamo Francesco e Tommaso e, ancora prima che soci, ci piace definirci due grandissimi amici che, da sempre, condividono la passione per la buona cucina e per il vino. Quello che volevamo creare non era un ristorante, una taverna o una semplice osteria, ma un ambiente caldo e familiare in cui vivere un’esperienza di convivialità arricchita dalla percezione dei sapori della nostra terra.

       Che sia un pranzo, una cena, o un aperitivo a farti conoscere il Bargello, siamo sicuri che il legame che instaureremo durerà nel tempo.
       
       Sarà soltanto l’inizio di una storia da raccontare.`
           },
    filosofia:{
       title:' Ricerchiamo la qualità per creare relazioni forti',
       paragraph:`Dai salumi ai formaggi, dalla pasta fresca artigianale alla carne, tutti i prodotti che selezioniamo provengono da piccoli produttori locali o da eccellenze nazionali e mondiali. Siamo convinti, infatti, che la ricerca della qualità sia l’aspetto più importante della relazione che creiamo con i nostri clienti e, proprio per questo, scegliamo ogni prodotto con la massima cura. 

       “Alcuni piatti e tantissime bottiglie”, bastano poche parole per riassumere la nostra proposta. Perché? Perché il vino è per noi la massima espressione di condivisione, sinonimo di esperienze, emozioni, sorrisi.
       
       Per questo, al Bargello il cibo è sempre in accompagnamento ad un buon calice.`
              },
    menu:{
       title:'Menu del Bargello',
       paragraph:' "La nostra idea di cucina si riassume in un menù che affianca agli antipasti, i primi e i secondi della tradizione toscana alcune eccellenze mondiali. Scopri tutti i nostri piatti e immergiti in un’esperienza culinaria autentica."',
       button:'Vai al Menù completo'
    },
    salottodelvino:{
       titleH2:'Il Salotto del Vino',
       titleH3:`L'enoteca del Bargello`,
       paragraph:` Un’offerta che comprende più di 1500 etichette da degustare nella nostra osteria o da scegliere per rendere speciale un momento o un’occasione`
    }

}

const HomeEnglish={
    hero1:{
    title:'Wine Bar Osteria in Siena',
    paragraph:'  Between Duomo and Piazza del Campo, in one of the oldest and most elegant streets in Siena’s city center. ',
    paragraph2:`An extraordinary spot where you can experience amazing wines and traditional food excellences.`
     
},
    chisiamo:{
       title:'About us',
       paragraph:` We are Francesco and Tommaso, we like to define ourselves as two great friends who have always shared the love for good food and wine. 
       What we wanted to create was not just a restaurant, but a warm and happy  place where everyone can enjoy a meal feeling comfortable like in his own house, chill like on a lazy sunday drinking good wine and live the real Tuscan experience.
       Come and visit us for lunch, dinner, or for an aperitivo, we are sure that the bond we will establish will last over time.`
           },
    filosofia:{
       title:'  Quality to create strong relationships',
       paragraph:`From fine tuscany meat to traditional cheese excellences, from handmade pasta to the best wines, all the products we select come from small local producers or from national and global excellence. We are convinced that the pursuit of quality is the most important aspect of the relationship we create with our customers and that’s why we choose each product very carefully.

We always say, about Il Bargello, “a bunch of dishes and many bottles” Why? Because wine is for us the maximum expression of sharing, synonymous with experiences, emotions, smiles.

At the Bargello food is always accompanied by a good glass of wine.
`
              },
    menu:{
       title:'Menú del Bargello',
       paragraph:'"Our idea of cuisine is a creative food experience that combines traditional Tuscan excellences with national and world excellences. Discover all our dishes!"',
       button:'Go to the complete Menu'
    },
    salottodelvino:{
       titleH2:'Il Salotto del Vino',
       titleH3:`Bargello Wine Shop`,
       paragraph:` An offer that includes more than 1500 labels to taste in our restaurant or to take away and drink with love wherever you prefer`
    }

}



const MenuItalian={
    title:'Menù Wine bar Osteria Il Bargello',
    paragraph:'Cucina tradizionale senese e toscana, ma non solo: un’esperienza culinaria che parte dai sapori della nostra terra per affacciarsi sul resto dell’Italia e del mondo'

}

const MenuEnglish={
    title:'Menù Wine Bar Osteria Il Bargello',
    paragraph:'Traditional tuscany cuisine and more: a culinary experience that starts from the flavors of our land to look out over the rest of Italy and, why not, the world. '

}



const EventsItalian={
    title:'I nostri Eventi',
    paragraph:'Degustazioni, cene e apericena, tutti i nostri eventi hanno in comune il buon cibo, il buon vino e uno scenario unico a due passi da Piazza del Campo e dal Duomo.'
}

const EventsEnglish={
    title:'Events',
    paragraph:'Tastings, dinners and aperitifs, all our events have in common good food, good wine and a unique setting just a 2 minutes walk from Piazza del Campo and the wonderful Duomo di Siena'
}



const IlSalottoItalian={
    Herotitle:'Il Salotto del Vino',
    HeroParagraph:`L'enoteca del Bargello`,
    titleSalotto:'Più che una semplice enoteca nel centro di Siena…',
    paragraphSalotto:`La nostra passione per il vino e l’ambizione di includere nella nostra offerta solo vini d’eccellenza, ci hanno spinti a prendere una decisione coraggiosa ma necessaria: allestire la nostra personale enoteca.

    Quel che ne è venuto fuori è un vero e proprio museo del vino, uno spazio in cui la scelta è guidata dalla cultura e dall’esperienza, caratterizzato da un’attenta e meticolosa valutazione della qualità di ogni bottiglia da aggiungere al nostro catalogo.
    `,
    winecartTitle:'Oltre 1500 etichette d’eccellenza',
    winecartParagraph:` Quando entriamo nella nostra cantina camminiamo tra i cipressi di Bolgheri, respiriamo l’aria carica di profumi delle colline di Montalcino e il nostro sguardo si perde tra le distese di vitigni vermigli di Montepulciano. Ma sentiamo in bocca anche l’aria sapida della costa oceanica spagnola e la pace sensoriale del vento Etneo. Oltre alle classiche e intramontabili etichette DOC e DOCG dei tradizionali vini toscani potete trovare un’ampia scelta di Vini Super Tuscan, eccellenze nazionali e internazionali selezionate con cura e pronte ad accompagnarvi nell’esperienza culinaria del Bargello o a farvi vivere più intensamente un’occasione particolare.

Andrea Zanibelli, il nostro sommelier, dopo aver ascoltato attentamente le tue esigenze e le tue preferenze, ti guiderà nella scelta del vino che fa più al caso tuo, per regalarti un’esperienza sensoriale unica, che porti con sé il ricordo della tradizione toscana.

    
    `}


const IlSalottoEnglish={
    Herotitle:'Wine Shop',
    HeroParagraph:`Il Bargello`,
    titleSalotto:'More than a simple wine shop in the center of Siena…',
    paragraphSalotto:`Our passion for wine and the ambition to include only excellent wines in our offer led us to take a not easy but necessary decision: to set up our own wine shop.

A real wine museum, a place where your choice is guided by culture and experience, by a careful professional evaluation of the quality of each bottle.

      `,
    winecartTitle:'Over 1500 labels of excellence',
    winecartParagraph:`When we enter our wine shop we walk among the cypresses of Bolgheri, we breathe the air full of scents of the hills of Montalcino and our gaze is lost among the expanses of red vines of Montepulciano. But we also feel the savory air of the Spanish ocean coast and the sensory peace of the Etna wind in our mouths. In addition to the classic and timeless DOC and DOCG labels of traditional Tuscan wines, you can find a wide selection of Super Tuscan wines, national and international excellence carefully selected. 

Andrea Zanibelli, our sommelier, after carefully listening to your needs and preferences, will guide you in choosing the wine that's right for you, to give you a unique sensory experience, which brings with it the memory of the Tuscan tradition.

    
    
    `
}



export{
    HomeItalian,
    HomeEnglish,
    MenuItalian,
    MenuEnglish,
    EventsItalian,
    EventsEnglish,
    IlSalottoItalian,
    IlSalottoEnglish,
    NavbarIta,
    NavbarEng,
}
