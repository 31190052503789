import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import './App.css';
import {Hero1,  Hero2, Hero3, Hero4, Hero5 } from './components/heros';
import Navigation from './components/navigation';
import './index.css'
export const UserContext=createContext()

function App() {

  const [isAuth,setIsAuth]=useState(false)
  const[language,setLanguage]=useState('ita')
  useEffect(()=>{
  
      if(window.sessionStorage.auth){
        setIsAuth(true)

       }
       if(!window.sessionStorage.auth && window.localStorage.jwtToken){
        

        axios.post('/tokenlogin',({token:window.localStorage.jwtToken }))
        .then((res)=>{
          if(res.data.status==='accepted' ){
            window.sessionStorage.setItem('auth',true)
            setIsAuth(true)
          }
          else if(res.data.status==='accepted' && res.data.token){
            window.sessionStorage.setItem('auth',true)
            window.localStorage.setItem('jwtTokwn',res.data.token)


          }else{
            setIsAuth(false)
          }})
        }
           
  
  },[])

  const Logout=()=>{
    setIsAuth(false)
    window.sessionStorage.clear()
    localStorage.clear()
}
  return (
    <UserContext.Provider value={{isAuth,setIsAuth}}>

    <Navigation  />
    <Hero5  />

    
    </UserContext.Provider>
  );
}

export default App;
