import React, { useEffect } from "react";
import { Hero1 ,Hero2, Hero3, Hero4} from "../components/heros";
import {Helmet} from "react-helmet";




const Home=(props)=>{
   
   
    return(
        <>
       
            <div className="w-screen overflow-hidden" >   
            <Helmet>
                   <meta charSet="utf-8" />
                <title>Osteria il Bargello Siena | Osteria tipica e Winebar in centro storico </title>
                <link rel="canonical" href="https://osteriailbargello.it" />
                <meta name="description" content="Un’osteria a due passi da piazza del campo, il duomo e piazza del mercato. Buon cibo e ottimo vino per un’esperienza unica nel centro di Siena." />
            </Helmet>
        <Hero1  language={props.language} setLang={props.setLang}  />
        <Hero3  language={props.language} setLang={props.setLang} />

     <Hero2 language={props.language} setLang={props.setLang}/>
     <Hero4 language={props.language} setLang={props.setLang} />

            </div>
          
        </>
     

    )
};


export default Home;
