import React from "react";
import logo from '../static/logo.png'
import { useNavigate } from "react-router-dom";



const NotFoundPage=()=>{
  const navigate=useNavigate()
    return(
        <div className="w-screen h-screen flex flex-col items-center justify-center">
           <img className="w-[20%]" src={logo} />
           <h1 className="Vidaloka mt-4 text-4xl">
             Ciao! La pagina che cerchi non esiste!
           </h1>
           <h1 className="Lora mt-4 text-2xl underline cursor-pointer" onClick={()=>navigate('/')}>
             Torna alla Home
           </h1>
        </div>
    )
}


export default NotFoundPage;
