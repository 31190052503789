import React, { useEffect, useState } from "react";

import menuicon from './../static/menuicon.png'
import closeicon from './../static/closeicon.png'
import tripicon from './../static/tripadvisoricon.png'
import googleicon from './../static/googleicon.png'
import itaflag from './../static/itaflagicon.png'
import engflag from './../static/engflagicon.png'
import logo from'./../static/logo.png'

import { NavbarIta,NavbarEng } from "./LanguageTexts";
import mailicon from './../static/mailicon.png'
import phoneicon from './../static/phoneicon.png'
import gpsicon from './../static/gpsicon.png'

import { useNavigate } from "react-router-dom";
import axios from "axios";


const Navbar=(props)=>{



   const[screen,setScreen]=useState(window.screen.width)
   const[mobileMenu,setMobileMenu]=useState(false)
   const[winespan,setWineSpan]=useState(false)
    const[texts,setTexts]=useState(NavbarIta)

   const googlerev='https://www.google.com/search?q=il+bargello+siena+review&sxsrf=AJOqlzV6kQy3yheYGOtS7F9VP5iQYPYHHQ%3A1678809235193&ei=k5gQZIyqC9KP9u8Py_iP8A0&ved=0ahUKEwjMv-_g49v9AhXSh_0HHUv8A94Q4dUDCA4&uact=5&oq=il+bargello+siena+review&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAE6CggAEEcQ1gQQsAM6BAgAEEc6BQgAEIAEOgYIABAWEB46CAghEBYQHhAdSgQIQRgAUKwEWJMbYN8faAFwA3gAgAGSAogBnwuSAQUwLjQuM5gBAKABAcgBCMABAQ&sclient=gws-wiz-serp#lrd=0x132a2cba1a72debf:0xe7e012fca1360823,1,,,,'
   const triprev='https://www.tripadvisor.it/Restaurant_Review-g187902-d7294480-Reviews-Il_Bargello-Siena_Tuscany.html#REVIEWS'
  
   const location=window.location.pathname
   const navigate=useNavigate()

   const getFile=(e)=>{
    e.preventDefault()
   
    axios({
      url: '/static/files/Ilbargello-vini.pdf', //your url
      method: 'GET',
      responseType: 'blob', // important
  }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Ilbargello-vini.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);})
  }
  useEffect(()=>{
    if(props.language==='ita'){setTexts(NavbarIta)}
    else if(props.language==='eng'){setTexts(NavbarEng)}

  })

   const smoothScrollAbout=async(div)=>{
    var elmntToView = document.getElementById(div);
    let location=window.location.pathname
    if(location==='/' || location==='/home' )
   { 
     elmntToView.scrollIntoView({ behavior: "smooth"}); }
    else{
         

            window.location.replace(`/home#${div}`)}
     }
   const smoothScrollWines=async(div)=>{
        var elmntToView = document.getElementById(div);
        let location=window.location.pathname
        if(location==='/vini' )
       { 
         elmntToView.scrollIntoView({ behavior: "smooth"}); }
        else{
             
    
                window.location.replace(`/vini#${div}`)}
         }
     

   const changePage=(page)=>{
    setMobileMenu(false)
    document.body.classList.remove("stop-scrolling")
    navigate(page)
//     var elmntToView = document.getElementById(div);
// elmntToView.scrollIntoView({ behavior: "smooth"}); 
   }
   
    return(
    <>  {(screen>=768 ) &&  <div className="Lora h-36 w-[100%] px-[8%] xl:px-[10%] absolute top-0 left-0 z-10 text-[#F1EAED] ">
         <div className="h-[50%] flex flex-row justify-between items-center  border-b-2 border-dotted border-gray-100/20"  >
            <div className="flex flex-row items-center justify-center gap-2  ">
            <img className="w-5 h-5 invert" src={gpsicon} />

                <h1>Via di Città, 55, 53100 Siena SI</h1>
            </div>
            <div className="flex flex-row gap-10 mt-1">
                <div className="flex flex-col  justify-center items-center cursor-pointer hover:bg-black/20 text-[#330100] hover:text-[#F1EAED] px-3 " onClick={()=> window.open(triprev, "_blank")}>
                <img className="w-10 h-10 invert" src={tripicon} />
                <h1 className="text-[#F1EAED] font-bold font-md">4.5</h1>
                </div>
                <div className="flex flex-col  justify-center items-center cursor-pointer hover:bg-black/20 text-[#330100] hover:text-[#F1EAED] px-3 " onClick={()=> window.open(googlerev, "_blank")}>



                <img className="w-10 h-10" src={googleicon} />
                 <h1 className=" text-[#F1EAED] font-bold font-md">4.4</h1>
                </div>


            </div>
            <div className="flex flex-col  gap-2 justify-end ">
            <div className="flex flex-row gap-2  justify-start items-center">
            <img className="w-5 h-5 invert" src={phoneicon} />

            <h1>0577 045649</h1>
           

            </div>

            <div className="flex flex-row gap-2 justify-center items-center">
            <img className="w-5 h-5 invert text-white" src={mailicon} />

                <h1>info@ilbargello55.com</h1>

            </div>
            </div>
             </div>
        
        
            <div className="w-full flex flex-row mt-2 md:gap-x-12 gap-y-4 xl:gap-y-2 uppercase text-md   font-bold flex-wrap  items-start  justify-center text-center text-[#F1EAED]"  >
              
                  <span className={((location==='/home' || location===''||location==='/' )?"border-[#F1EAED] ":"")+" spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer" } onClick={()=>changePage('/home')}  >Home</span>
                
                  <span className={((location==='/menu')?"border-[#F1EAED] ":"")+"  spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"} onClick={()=>changePage('/menu')}  >Menu</span>

                   <span className={((location==='/vini')?"border-[#F1EAED] ":"")+" nowrap spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"} onClick={()=>changePage('/vini')} >{texts.Salotto}</span>
                        <span className={((location==='/vini')?"border-[#F1EAED] ":"")+" inline-block spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"}   onClick={(e)=>getFile(e)} >{texts.Carta}</span>

                    

                <span  className="ml-5 spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"   onClick={()=>smoothScrollAbout('chisiamo')} >{texts.About}</span>

            <span className={((location==='/eventi')?"border-[#F1EAED] ":"")+" spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"}  onClick={()=>changePage('/eventi')} >{texts.Events}</span>
            <span className={((location==='/photogallery')?"border-[#F1EAED] ":"")+"ml-5 spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"}  onClick={()=>changePage('/gallery')} >PhotoGallery</span>

          {props.language==='ita'? <img src={engflag} onClick={props.setLang}  className="cursor-pointer w-[20px]" />:
            <img src={itaflag} onClick={props.setLang}  className="cursor-pointer w-[20px]" />}


                     </div>
          
          </div>

       }
        {(screen<768) && <> <div className="Lora flex flex-row px-4 py-2 justify-between items-center bg-black/50 h-fit w-screen overflow-hidden fixed  top-0  z-10 text-[#F1EAED]">
                            <img  className=" w-[55px] invert " src={logo} />
 
          
            <div onClick={()=>{setMobileMenu(true); document.body.classList.add("stop-scrolling");}} className=" w-fit h-fit rounded-md cursor-pointer " ><img src={menuicon} className=' w-[35px] invert hover:hue-rotate-180 ' /></div>
           
            </div>
            {mobileMenu && <div className="Lora flex-initial fixed top-0 right-0 z-50 w-[325px] max-w-[100%] h-screen bg-black/90 lateralAnimation text-[#F1EAED]">
                <div className=" border-b border-[#F1EAED] flex flex-row justify-between p-3 h-fit" >
                <div className="w-fit h-fit">   <img  className=" w-[55px] invert " src={logo} /></div>
                <div  onClick={()=>{setMobileMenu(false); document.body.classList.remove("stop-scrolling");}} className="cursor-pointer w-fit h-fit p-2 rounded-md hover:bg-orange-800/30"> <img className="w-8 invert" src={closeicon} /></div>
                </div>
                
                <div className="h-fit w-full mt-10 " >
                <div className="px-5 h-full gap-10 flex flex-col uppercase  font-bold flex-wrap items-start justify-center  text-xl"  >
                <span className={((location==='/home' || location==='')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED] border-b-4 border-transparent cursor-pointer" } onClick={()=>changePage('/home')}  >Home</span>
                
                <span className={((location==='/menu')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"} onClick={()=>changePage('/menu')}  >Menu</span>
                    
              <span className={((location==='/vini')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED]   border-b-2 border-transparent cursor-pointer"} onClick={()=>changePage('/vini')} >{texts.Salotto}</span>
            <span className={((location==='/vini')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED]   border-b-2 border-transparent cursor-pointer"}   onClick={(e)=>getFile(e)} >{texts.Carta}</span>

                 
              <span  className="spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"   onClick={()=>smoothScrollWines('chisiamo')} >Chi siamo</span>
             <span className={((location==='/eventi')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"}  onClick={()=>changePage('/eventi')} >{texts.Events}</span>
             <span className={((location==='/gallery')?"border-[#F1EAED] ":"")+"spanAnimation hover:border-[#F1EAED]  border-b-2 border-transparent cursor-pointer"}  onClick={()=>changePage('/gallery')} >PhotoGallery</span>

                     </div>
                </div>
                 </div>}
            </>}

         </>
    )
}


export default Navbar;
