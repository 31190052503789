import React, { useEffect, useState} from "react";
import axios from 'axios';
import closeiconred from './../static/closeiconred.png'
import plusicon from './../static/closeicon.png'
import loadicon from './../static/loadicon.svg'
import menuarrow from './../static/menuarrowicon.png'


import trashicon from './../static/trashicon.png'

import { useNavigate } from "react-router-dom";

const Adminlogin=()=>{
   const[userData,setUserData]=useState({username:'',password:''})
   const[dataError,setDataError]=useState({})
  const[validationForm,setValidationForm]=useState(false)
   const navigate=useNavigate()

  useEffect(()=>{

  },[])
const changeInput=(e)=>{
    
    const {id,value}=e.target
   
    const tempError=dataError
    if(tempError[id]){
      delete tempError[id]
    }
    setDataError(tempError)
    
    setUserData({...userData,[id]:value})
}

const validateForm = () => {
    

    // effettua i controlli su email e password
    if (userData.username==='') {
      setDataError((errors) => ({
        ...errors,
        username:
          `Hai lasciato vuoto il campo username`,
      }))
     
    }
    
    // effettua i controlli su email e password
    if (userData.password==='') {
        setDataError((errors) => ({
          ...errors,
          password:
            `Hai lasciato vuoto il campo password`,
        }))
       
      }
    else{
      setValidationForm(true)
    }
  }

const sendData=()=>{
     setValidationForm(false)
         axios.post('loginuser',{
             userData:userData
         })
        .then((res)=>{
          if(res.data.status==='accepted' && res.data.token){
          
            window.sessionStorage.setItem('auth',true)
            window.localStorage.setItem('jwtToken',res.data.token)


           }
           else{
            setDataError({username:'i dati inseriti non sono corretti'})
           }

        })
    
        .then(()=>{ window.location.reload() })
        .catch(err=>{console.log(err)})
    }
  
    useEffect(()=>{
        if(Object.values(dataError).length===0 && validationForm===true){
          sendData()
         
        }
      })
    

    return(
        <div className="overflow-hidden w-full h-full flex flex-col justify-start items-center bg-[#0e0d0a]" >

           <div className="bg-black text-white font-bold flex flex-col gap-10 justify-center items-center max-w-[350px] py-5 w-[90%] h-fit m-4 mt-20 rounded-md  border-4 border-[#c18d5b]"   >
                    <h1 className="font-bold text-white uppercase">Pannello Admin</h1>
                    {dataError.username? <h1 className="font-bold text-red-600">{dataError.username}</h1>:null}
                    {dataError.password? <h1 className="font-bold text-red-600">{dataError.password}</h1>: null}

                  
                    <div className="flex flex-col gap-10 justify-center items-center" >
                       <div className="flex flex-col gap-2 justify-center items-center">
                       <label for='username' className="text-white bold "> Username</label>
                        <input onChange={(e)=>changeInput(e)}   name="username" id='username' className="text-center text-black bg-white rounded-md focus:outline outline-4 outline-[#c18d5b]"></input>
                        </div>
                        <div className="flex flex-col gap-2 justify-center items-center">

 
                        <label for='password' className="text-white bold "> Password</label>

                        <input onChange={(e)=>changeInput(e)} name="password" id='password' type="password" className="text-center text-black bg-white rounded-md focus:outline outline-4 outline-[#c18d5b]"></input>
                        </div>
                       
                    {validationForm?<button type="button" className="p-3 rounded-md px-10 hover:bg-white hover:text-black  outline outline-4 outline-[#c18d5b] " disabled><svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg></button>:<button onClick={()=>validateForm()} className=" p-3 rounded-md px-10 hover:bg-white hover:text-black  outline outline-4 outline-[#c18d5b] ">Accedi</button> }
                    </div>
           </div>
        </div>
    )
}



const AdminPanel=()=>{
   const[view,setView]=useState('menuitaliano')
   const[mouseIn,setMouseIn]=useState(false)
  const[menuWidth,setMenuWidth]=useState('50px')
  const[rotationArrow,setRotationArrow]=useState('0deg')
  const[widthComplete,setWidthComplete]=useState(false)
const resize=()=>{
 menuWidth==='200px'? setMenuWidth('50px'):setMenuWidth('200px')
rotationArrow==='0deg'?setRotationArrow('180deg'):setRotationArrow('0deg')
if(widthComplete){setWidthComplete(false)}
setTimeout(()=>{
  if(!widthComplete){setWidthComplete(true)}
},1100)
}
    return(
        <div className="w-full h-fit  flex flex-col justify-center xl:items-center items-start bg-slate-300 px-20" >


     <div id="menuAdmin" style={{width:menuWidth}} className=" shadow-xl shadow-gray-400 admintranstion bg-white fixed left-0 top-0 pt-[150px] w-[120px]  h-full flex flex-col justify-start items-stretch  rounded-md uppercase" >
       <div className="top-0 right-0 absolute">
       <img style={{rotate:rotationArrow}} id='menuarrow' className='admintranstion w-[50px] after:rotate-90 cursor-pointer' src={menuarrow} onClick={()=>resize()} /> 

       </div>
       {widthComplete &&    <div className="admintranstionText flex flex-col  text-white font-bold items-center" >

         

          <button className="cursor-pointer  uppercase  h-fit w-full text-slate-800  hover:bg-slate-300   p-4" onClick={()=>setView('menuitaliano')} >Menu italiano</button>
          <button className="cursor-pointer uppercase  h-fit w-full text-slate-800  hover:bg-slate-300   p-4" onClick={()=>setView('menuinglese')}>Menu Inglese</button>
           
          
            <button className="cursor-pointer uppercase  h-fit w-full text-slate-800  hover:bg-slate-300    p-4" onClick={()=>setView('cartadeivini')} >Carta dei vini</button>
            <button className="cursor-pointer uppercase  h-fit w-full text-slate-800  hover:bg-slate-300    p-4" onClick={()=>setView('eventi')} >Eventi</button>
             
             
          </div>}

          
       </div>
     
       {view==='menuitaliano' && <MenuChange />      }

       {view==='menuinglese' && <MenuEnChange />}

       {view==='cartadeivini' && <WineCard />}


       {view==='eventi' && <EventCard />}



       
       
      
        </div>
    )
}





const MenuChange=()=>{
 const [menus,setMenus]=useState({antipasti:[],primi:[],secondi:[],dessert:[]})
 const [menusHome,setMenusHome]=useState([])
 const[menusHomeCount,setMenusHomeCount]=useState(0)
 const[alertSend,setAlertSend]=useState(false)
 const[alertReset,setAlertReset]=useState(false)
 const[sendConfirm,setSendConfirm]=useState(false)

 const [pageWidth, setPageWidth] = useState(window.innerWidth);

 const changeDesc=(e,index,plate)=>{
    const{id,value}=e.target
    let tempPlate=menus[plate]

    tempPlate[index]={descrizione:value,prezzo: tempPlate[index].prezzo}
    setMenus({...menus,[plate]:tempPlate})


  
}

const changePrice=(e,index,plate)=>{
  const{value}=e.target
  let tempPlate=menus[plate]

  tempPlate[index]={descrizione:tempPlate[index].descrizione,prezzo:value}
  setMenus({...menus,[plate]:tempPlate})


}
const erasePlate=(index,plate)=>{
  let tempPlate=menus[plate]
  let forDelete=menus[plate][index]
  let newPlate=tempPlate.filter(item=> item!== forDelete)
  setMenus({...menus,[plate]:newPlate})

}

const addPlate=(index,plate)=>{

  let tempPlate=menus[plate]
 
  tempPlate.splice(index+1,0,{descrizione:'',prezzo:''})
  setMenus({...menus,[plate]:tempPlate})

}
const menuHome=(item,e)=>{
  if(e.target.checked===true){
    let tempMenuHome=menusHome

    tempMenuHome.push(item)
    setMenusHomeCount(menusHomeCount+1)
    setMenusHome(tempMenuHome)
  }
  else if(e.target.checked===false){
    let tempMenuHome=menusHome
    setMenusHomeCount(menusHomeCount-1)

   let newTempMenu=tempMenuHome.filter(x=>x!=item)

   setMenusHome(newTempMenu)
  }
   


}

const resetMenuHome=()=>{
  setMenusHomeCount(0)
  setMenusHome([])
  let check=document.getElementsByName('checkbox')
  for(let x of check){
    x.checked=false
  }

}
const fetchData=()=>{
  setAlertReset(false)
  axios.post('/getmenu')
  .then((res)=>{
    let datas=res.data.data
    const tempMenu={antipasti:datas.antipasti,primi:datas.primi,secondi:datas.secondi,dessert:datas.dessert}
    setMenus(tempMenu)
  })
  
  .catch(err=> {})
}
useEffect(()=>{
  fetchData()
},[])

useEffect(()=>{
  
  if(sendConfirm)
  sendData()
})
const sendData=()=>{
  setSendConfirm(false)
  setAlertSend(false)
axios.post('/insertmenu',{
   menus:menus,
  menusHome:menusHome})
.then((res)=> {})
.catch((err)=>{})

}

  return(
    <div className="w-fit h-fit mt-6 max-w-[100%] flex flex-col justify-center items-center   ">
        <div className="h-fit mt-10 w-[100%]  flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
        <h1 className="text-black uppercase   text-md mb-2 ">Menú Lingua Italiana</h1>

       <div className="flex flex-col h-fit justify-center items-center w-[100%] " >

        <h1 className="text-blue-800 font-mono uppercase text-xl ">Antipasti</h1>

       </div>
       <div className="flex flex-col w-fit h-fit px-5">
       <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
      
     {menus.antipasti && <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
     <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
        <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
        <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>
 
         </div>}
         {(!menus.antipasti || menus.antipasti.length===0) && 
     
     <div className="flex flex-col border-2 rounded-md w-fit h-fit " >
     <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'antipasti')} />
     </div>}  
       {menus.antipasti && menus.antipasti.map((x,index)=>
       
            <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
     <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />
              <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'antipasti')}} />
              <input className="bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-[70px] px-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'antipasti')}} />
 
              <div className="flex flex-row  w-fit h-fit" >
              <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'antipasti')} title='Elimina Pietanza' />
              <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'antipasti')}  title='Aggiungi Pietanza' />
              </div>

             
            </div>
        )}
       </div>
       </div>
 
       </div>
       <div className="h-fit mt-5 w-fit min-w-[100%] max-w-[100%] flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
      
      <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
       <h1 className="text-blue-800 font-mono uppercase text-xl">Primi</h1>

      </div>
      <div className="flex flex-col w-fit h-fit px-5">
      <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
         
       
      {menus.primi &&<div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
     <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
        <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
        <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>
 
         </div>}
     {(!menus.primi || menus.primi.length===0) && 
     
     <div className="flex flex-col border-2 rounded-md w-fit h-fit" >
     <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'primi')} />
     </div>}   
      {menus.primi && menus.primi.map((x,index)=>
           <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
                <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

             <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'primi')}} />
             <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-[70px]  pr-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'primi')}} />

             <div className="flex flex-row  w-fit h-fit" >
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'primi')} title='Elimina Pietanza' />
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'primi')} title='Aggiungi Pietanza' />
             </div>

            
           </div>
       )}
      </div>
      </div>

      </div>   <div className="h-fit mt-5 w-fit min-w-[100%]  max-w-[100%] flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
      
      <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
       <h1 className="text-blue-800 font-mono uppercase text-xl">Secondi</h1>

      </div>
      <div className="flex flex-col w-fit h-fit px-5">
      <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
       
      
      {menus.secondi && <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
     <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
        <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
        <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>
 
         </div>}
      {(!menus.secondi || menus.secondi.length===0) && 
     
     <div className="flex flex-col border-2 rounded-md w-fit h-fit" >
     <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'secondi')} />
     </div>}   
      {menus.secondi && menus.secondi.map((x,index)=>
           <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
                <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

             <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'secondi')}} />
             <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-[70px]  pr-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'secondi')}} />

             <div className="flex flex-row w-fit h-fit" >
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'secondi')} title='Elimina Pietanza' />
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,`secondi`)} title='Aggiungi Pietanza' />
             </div>

            
           </div>
       )}
      </div>
      </div>

      </div>   <div className="h-fit my-5 w-fit min-w-[100%]  max-w-[100%] flex flex-col gap-2 justify-center items-center text-black   bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
      
      <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
       <h1 className="text-blue-800 font-mono uppercase text-xl">Dessert</h1>

      </div>
      <div className="flex flex-col w-fit h-fit px-5">
      <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
         
        
      {menus.dessert &&  <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
     <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
        <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
        <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>
 
         </div>}
      {(!menus.dessert || menus.dessert.length===0) && 
     
     <div className="text-gray-700 flex flex-col border-2 rounded-md w-fit h-fit" >
     <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'dessert')} />
     </div>}   
      {menus.dessert && menus.dessert.map((x,index)=>
           <div className="gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
                <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

             <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3"  value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'dessert')}} />
             <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-[70px] pr-3"  value={x.prezzo} onChange={(e)=>{changePrice(e,index,'dessert')}} />

             <div className="flex flex-row w-fit h-fit" >
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'dessert')} title='Elimina Pietanza' />
             <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'dessert')} title='Aggiungi Pietanza' />
             </div>

            
           </div>
       )}
      </div>

      </div>

      </div>
      {alertSend && <div className="z-10 flex flex-col gap-y-4 fixed top-0 rounded-md bg-black/50 w-full h-full p-5 border-2 items-center justify-center" >
      <div className="flex flex-col gap-y-4 rounded-md bg-white w-[350px] h-fit p-5 items-center justify-center" >

        <h1 className="font-bold text-center">Vuoi Confermare l'invio del nuovo menú? </h1>
        <h1 className="text-center text-sm ">Sostituirá in modo permanente quello attuale.</h1>

        <div className="w-fit flex flex-row gap-10 my-10">
      
        <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800"  onClick={()=>setSendConfirm(true)}>Conferma</button>
        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertSend(false)}>Annulla</button>

      </div>
     </div>
             </div>
             }
                
                {alertReset && <div className="z-10 flex flex-col gap-y-4 fixed top-0 rounded-md bg-black/50 w-full h-full p-5 border-2 items-center justify-center" >
       <div className="flex flex-col gap-y-4 rounded-md bg-white w-[350px] h-fit p-5 items-center justify-center" >

        <h1 className="font-bold ">Vuoi confermare il reset del menú? </h1>
        <h1 className="text-center text-sm ">Le nuove modifiche verranno eliminate definitivamente e verrá recuperato il menu attuale.</h1>

        <div className="w-fit flex flex-row gap-10 my-10">
      
        <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={()=>fetchData()}>Conferma Reset</button>
        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertReset(false)}>Annulla</button>

     </div>

     </div>
             </div>
             }
   {pageWidth>1055  &&   <div className="mr-[10px] fixed right-0 top-[200px] w-fit flex flex-col gap-10 my-10 rounded-md  bg-transparent px-5 py-10">
      
        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertReset(true)}>Reset Menu</button>

        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>resetMenuHome()}>Reset Home Menu</button>
        <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={()=>setAlertSend(true)}>Salva</button>

       </div>}
  {pageWidth<1055  &&   <div className="fixed right-[20%] top-0 w-fit flex flex-row gap-10 my-1 rounded-md  bg-black/50 px-3 py-1 hover:bg-black">
      
        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-2  hover:bg-orange-800" onClick={()=>setAlertReset(true)}>Reset Menu</button>

        <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-2  hover:bg-orange-800" onClick={()=>resetMenuHome()}>Reset Home Menu</button>
        <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-2  hover:bg-blue-800" onClick={()=>setAlertSend(true)}>Salva</button>

       </div>}

     </div>

                       
  )
}



const MenuEnChange=()=>{
  const [menus,setMenus]=useState({antipasti:[],primi:[],secondi:[],dessert:[]})
  const [menusHome,setMenusHome]=useState([])
  const[menusHomeCount,setMenusHomeCount]=useState(0)
  const[alertSend,setAlertSend]=useState(false)
  const[alertReset,setAlertReset]=useState(false)
  const[sendConfirm,setSendConfirm]=useState(false)
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

 
  const changeDesc=(e,index,plate)=>{
     const{id,value}=e.target
     let tempPlate=menus[plate]
 
     tempPlate[index]={descrizione:value,prezzo: tempPlate[index].prezzo}
     setMenus({...menus,[plate]:tempPlate})
 
 
   
 }
 
 const changePrice=(e,index,plate)=>{
   const{value}=e.target
   let tempPlate=menus[plate]
 
   tempPlate[index]={descrizione:tempPlate[index].descrizione,prezzo:value}
   setMenus({...menus,[plate]:tempPlate})
 
 
 }
 const erasePlate=(index,plate)=>{
   let tempPlate=menus[plate]
   let forDelete=menus[plate][index]
   let newPlate=tempPlate.filter(item=> item!== forDelete)
   setMenus({...menus,[plate]:newPlate})
 
 }
 
 const addPlate=(index,plate)=>{
 
   let tempPlate=menus[plate]
  
   tempPlate.splice(index+1,0,{descrizione:'',prezzo:''})
   setMenus({...menus,[plate]:tempPlate})
 
 }
 const menuHome=(item,e)=>{
   if(e.target.checked===true){
     let tempMenuHome=menusHome
 
     tempMenuHome.push(item)
     setMenusHomeCount(menusHomeCount+1)
     setMenusHome(tempMenuHome)
   }
   else if(e.target.checked===false){
     let tempMenuHome=menusHome
     setMenusHomeCount(menusHomeCount-1)
 
    let newTempMenu=tempMenuHome.filter(x=>x!=item)
 
    setMenusHome(newTempMenu)
   }
    
 
 
 }
 
 const resetMenuHome=()=>{
   setMenusHomeCount(0)
   setMenusHome([])
   let check=document.getElementsByName('checkbox')
   for(let x of check){
     x.checked=false
   }
 
 }
 const fetchData=()=>{
   axios.post('/getENmenu')
   .then((res)=>{
     let datas=res.data.data
     const tempMenu={antipasti:datas.antipasti,primi:datas.primi,secondi:datas.secondi,dessert:datas.dessert}
     setMenus(tempMenu)
   })
   
   .catch(err=> {})
 }
 useEffect(()=>{
   fetchData()
 },[])
 
 useEffect(()=>{
   if(sendConfirm)
   sendData()
 })
 const sendData=()=>{
   setSendConfirm(false)
   setAlertSend(false)
 axios.post('/insertENmenu',{
    menus:menus,
   menusHome:menusHome})
 .then((res)=>{})
 .catch((err)=>{})
 
 }
 
   return(
    <div className="w-fit h-fit mt-6 max-w-[90%] flex flex-col justify-center items-center   ">
    <div className="h-fit mt-10 min-w-[100%] w-fit max-w-[100%] flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
    <h1 className="text-black uppercase   text-md mb-2 ">Menú Lingua Inglese</h1>

   <div className="flex flex-col h-fit justify-center items-center w-[100%] " >

    <h1 className="text-blue-800 font-mono uppercase text-xl ">Antipasti</h1>

   </div>
   <div className="flex flex-col w-fit h-fit px-5">
   <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
  
 {menus.antipasti && <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
 <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
    <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
    <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>

     </div>}
     {(!menus.antipasti || menus.antipasti.length===0) && 
 
 <div className="flex flex-col border-2 rounded-md w-fit h-fit " >
 <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'antipasti')} />
 </div>}  
   {menus.antipasti && menus.antipasti.map((x,index)=>
   
        <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
 <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />
          <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'antipasti')}} />
          <input className="bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-[70px] px-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'antipasti')}} />

          <div className="flex flex-row  w-fit h-fit" >
          <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'antipasti')} title='Elimina Pietanza' />
          <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'antipasti')}  title='Aggiungi Pietanza' />
          </div>

         
        </div>
    )}
   </div>
   </div>

   </div>
   <div className="h-fit mt-5 w-fit min-w-[100%] max-w-[100%] flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
  
  <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
   <h1 className="text-blue-800 font-mono uppercase text-xl">Primi</h1>

  </div>
  <div className="flex flex-col w-fit h-fit px-5">
  <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
     
   
  {menus.primi &&<div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
 <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
    <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
    <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>

     </div>}
 {(!menus.primi || menus.primi.length===0) && 
 
 <div className="flex flex-col border-2 rounded-md w-fit h-fit" >
 <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'primi')} />
 </div>}   
  {menus.primi && menus.primi.map((x,index)=>
       <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
            <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

         <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'primi')}} />
         <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-[70px]  pr-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'primi')}} />

         <div className="flex flex-row  w-fit h-fit" >
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'primi')} title='Elimina Pietanza' />
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'primi')} title='Aggiungi Pietanza' />
         </div>

        
       </div>
   )}
  </div>
  </div>

  </div>   <div className="h-fit mt-5 w-fit min-w-[100%]  max-w-[100%] flex flex-col gap-2 justify-center items-center text-black  bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
  
  <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
   <h1 className="text-blue-800 font-mono uppercase text-xl">Secondi</h1>

  </div>
  <div className="flex flex-col w-fit h-fit px-5">
  <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
   
  
  {menus.secondi && <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
 <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
    <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
    <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>

     </div>}
  {(!menus.secondi || menus.secondi.length===0) && 
 
 <div className="flex flex-col border-2 rounded-md w-fit h-fit" >
 <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'secondi')} />
 </div>}   
  {menus.secondi && menus.secondi.map((x,index)=>
       <div className="text-gray-700 gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
            <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

         <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3" value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'secondi')}} />
         <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-[70px]  pr-3" value={x.prezzo} onChange={(e)=>{changePrice(e,index,'secondi')}} />

         <div className="flex flex-row w-fit h-fit" >
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'secondi')} title='Elimina Pietanza' />
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,`secondi`)} title='Aggiungi Pietanza' />
         </div>

        
       </div>
   )}
  </div>
  </div>

  </div>   <div className="h-fit my-5 w-fit min-w-[100%]  max-w-[100%] flex flex-col gap-2 justify-center items-center text-black   bg-white shadow-md hover:shadow-2xl shadow-gray-400 p-4 border rounded-md" >
  
  <div className="flex flex-col h-fit justify-center items-center w-[100%]" >
   <h1 className="text-blue-800 font-mono uppercase text-xl">Dessert</h1>

  </div>
  <div className="flex flex-col w-fit h-fit px-5">
  <div className="flex flex-col justify-center items-center gap-x-5 w-fit" >
     
    
  {menus.dessert &&  <div className="flex flex-row items-center justify-start gap-10   w-[100%] border border-gray-2 mb-2 rounded-md px-1"> 
 <h1 className="text-center rounded-md border-2 border-none  w-max h-max  text-black font-bold "  >Home</h1>
    <h1 className="text-center rounded-md border-2 border-none  w-max h-max ml-32 text-black font-bold pr-3 px-3 pl-4"  >Descrizione</h1>
    <h1 className="text-center rounded-md border-2 border-none text-black ml-52 px-1 font-bold" >Prezzo</h1>

     </div>}
  {(!menus.dessert || menus.dessert.length===0) && 
 
 <div className="text-gray-700 flex flex-col border-2 rounded-md w-fit h-fit" >
 <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(0,'dessert')} />
 </div>}   
  {menus.dessert && menus.dessert.map((x,index)=>
       <div className="gap-x-4  gap-y-1 flex flex-row justify-between items-center  w-[100%] py-3"> 
            <input className="w-4  h-4 cursor-pointer" name='checkbox' type='checkbox' onChange={(e)=>{menuHome(x,e)}} disabled={menusHomeCount>6?true:false} />

         <input className="px-2 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-max h-max min-w-[500px]  pr-3"  value={x.descrizione} onChange={(e)=>{changeDesc(e,index,'dessert')}} />
         <input className="px-3 bg-slate-100 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-[70px] pr-3"  value={x.prezzo} onChange={(e)=>{changePrice(e,index,'dessert')}} />

         <div className="flex flex-row w-fit h-fit" >
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1" src={closeiconred} onClick={()=>erasePlate(index,'dessert')} title='Elimina Pietanza' />
         <img className="w-6 cursor-pointer hover:bg-black/30 rounded-full p-1 rotate-45" src={plusicon} onClick={()=>addPlate(index,'dessert')} title='Aggiungi Pietanza' />
         </div>

        
       </div>
   )}
  </div>

  </div>

  </div>
  {alertSend && <div className="z-10 flex flex-col gap-y-4 fixed top-0 rounded-md bg-black/50 w-full h-full p-5 border-2 items-center justify-center" >
  <div className="flex flex-col gap-y-4 rounded-md bg-white w-[350px] h-fit p-5 items-center justify-center" >

    <h1 className="font-bold text-center">Vuoi Confermare l'invio del nuovo menú? </h1>
    <h1 className="text-center text-sm ">Sostituirá in modo permanente quello attuale.</h1>

    <div className="w-fit flex flex-row gap-10 my-10">
  
    <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800"  onClick={()=>setSendConfirm(true)}>Conferma</button>
    <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertSend(false)}>Annulla</button>

  </div>
 </div>
         </div>
         }
            
            {alertReset && <div className="z-10 flex flex-col gap-y-4 fixed top-0 rounded-md bg-black/50 w-full h-full p-5 border-2 items-center justify-center" >
   <div className="flex flex-col gap-y-4 rounded-md bg-white w-[350px] h-fit p-5 items-center justify-center" >

    <h1 className="font-bold ">Vuoi confermare il reset del menú? </h1>
    <h1 className="text-center text-sm ">Le nuove modifiche verranno eliminate definitivamente e verrá recuperato il menu attuale.</h1>

    <div className="w-fit flex flex-row gap-10 my-10">
  
    <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={()=>fetchData()}>Conferma Reset</button>
    <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertReset(false)}>Annulla</button>

 </div>

 </div>
         </div>
         }
   {pageWidth>1055  &&   <div className="mr-[10px] fixed right-0 top-[200px] w-fit flex flex-col gap-10 my-10 rounded-md  bg-transparent px-5 py-10">
      
      <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>setAlertReset(true)}>Reset Menu</button>

      <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-3  hover:bg-orange-800" onClick={()=>resetMenuHome()}>Reset Home Menu</button>
      <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={()=>setAlertSend(true)}>Salva</button>

     </div>}
{pageWidth<1055  &&   <div className="fixed right-[20%] top-0 w-fit flex flex-row gap-10 my-1 rounded-md  bg-black/50 px-3 py-1 hover:bg-black">
    
      <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-2  hover:bg-orange-800" onClick={()=>setAlertReset(true)}>Reset Menu</button>

      <button className="shadow-md shadow-gray rounded-md bg-orange-500 text-white font-bold px-3 p-2  hover:bg-orange-800" onClick={()=>resetMenuHome()}>Reset Home Menu</button>
      <button className="shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-2  hover:bg-blue-800" onClick={()=>setAlertSend(true)}>Salva</button>

     </div>}

 </div>
                        
   )
 }

 const WineCard=()=>{
const[file,setFile]=useState({data:''})
const[confirmSend,setConfirmSend]=useState(false)
const[confirmDown,setConfirmDown]=useState(false)
const[confirmUpload,setConfirmUpload]=useState(false)


const[loaded,setLoaded]=useState(false)
 const [errors,setErrors]=useState({})
  const pdfUpload=(e)=>{
    setErrors({})
    if(e.target.files[0].type !='application/pdf'){
      setErrors({pdf:'Il file é di un formato diverso da .PDF'})
      setConfirmUpload(false)
    }
  
    else if(e.target.files[0].type ==='application/pdf'){
     
       
       setFile(e.target.files[0])
    }


  } 

useEffect(()=>{
  if(file.data!='' && confirmSend){
    sendFile()
  }
})
const getFile=(e)=>{
  e.preventDefault()
  setConfirmDown(true)
  axios({
    url: '/static/files/Ilbargello-vini.pdf', //your url
    method: 'GET',
    responseType: 'blob', // important
}).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'Ilbargello-vini.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}).then(()=>  setConfirmDown(false)
)
}

const sendFile=()=>{
  setConfirmSend(false)
 setConfirmUpload(true)
 const config={
  headers:{
    'content-type': 'multipart/form-data'
  }
}
  const dati = new FormData(); 
dati.append('file', file); 
  axios.post('/savefile',dati,config)
  .then(res=>{
    if(res.data.status==='accepted'){
      setLoaded(true)
      setConfirmUpload(false)

    }
  })
  .then(err=>{console.log(err)})

}
   return(
     <div className="w-screen h-screen p-20 flex flex-col justify-start items-center">
                
         

         <div className="h-fit mt-20  min-w-[100%] w-fit flex flex-col gap-20 justify-center items-center text-black  ">
       
       <div className="text-white font-bold flex flex-col w-[350px] h-[150px] justify-center items-center gap-3 rounded-md bg-white shadow-md shadow-gray-400 p-10" >
       <h1 className="text-black uppercase text-xl"> carta dei vini </h1>
       <div>

       </div>
        
    {confirmDown?<button className="flex flex-row justify-center shadow-md w-[200px] shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" disabled><img className="animate-spin h-5 w-5 mr-3 ..." src={loadicon} viewBox="0 0 24 24" ></img></button>:<button className="w-[200px] shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={(e)=>getFile(e)}>Scarica</button> }

 
       </div>  
       
       <div className=" text-white font-bold flex flex-col  gap-5 justify-center items-center w-[350px] h-[250px]  rounded-md bg-white shadow-md shadow-gray-400 p-10" >
       {errors.pdf && <h1 className="font-bold text-red-500">{errors.pdf}</h1>}
        <h1 className="text-black uppercase text-md mt-0 mb-6">Inserisci nuova carta dei vini</h1>
        <input type='file' name="cartavini" className=" cursor-pointer file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-blue-700
                hover:file:bg-violet-100 text-black w-fit max-w-[100%]" onChange={(e)=>pdfUpload(e)}></input>
       {loaded && <h1 className="text-black">Carta dei Vini Caricata! </h1>}

        {confirmUpload && <button className="flex flex-row justify-center shadow-md w-[200px] shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" disabled><img className="animate-spin h-5 w-5 mr-3 ..." src={loadicon} viewBox="0 0 24 24" ></img></button>}
        {file.data===''?<button disabled className="w-[200px] shadow-md shadow-gray rounded-md disabled:bg-slate-500 bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800" onClick={(e)=>{setConfirmUpload(true);setConfirmSend(true)}}>Carica</button>:<button className="w-[200px] shadow-md shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800 "  onClick={(e)=>{setConfirmUpload(true);setConfirmSend(true)}} >Carica</button> }



 
       </div>  
       </div>  

 
      </div>
 
                        
   )
 }

 const EventCard=()=>{
   const[formValues,setFormValues]=useState({title:'',description:'',date:'',images:[]})
   const[formError,setFormError]=useState({})
   const[formValidation,SetFormValidation]=useState(false)
   const[succesAlert,setSuccesAlert]=useState(false) 
   const [modTable,setModTable]=useState(false)
  const[buttonCircle,setButtonCircle]=useState(false)
   const[eventTable,setEvenTable]=useState([])
   const[erasedEvents,setErasedEvents]=useState([])
 const setItem=(e)=>{
  const {name,value}=e.target
  const tempErr=formError
  if(tempErr[name]){delete tempErr[name]}
  setFormError(tempErr)

 setFormValues({...formValues,[name]:value})

 }

 const imageHandleChange =async (event) => {
  if(formError.images){
      const tempError=formError
     delete tempError.images
      
     setFormError(tempError)
  }
    let tempImage=formValues.images
   for(let file of event.target.files){
    
     tempImage.push(file)
    
     }
     setFormValues({...formValues, images:tempImage});

   }
  

  const handleDelete = (index) => {
    const newImages = formValues.images.filter((_, i) => i !== index);
    setFormValues({...formValues,images:newImages});
  }

  const validate=(e)=>{
    e.preventDefault()
    if(formValues.title===''){
      setFormError((errors)=>({...errors,title:`Il titolo dell'evento é obbligatorio`}))
      SetFormValidation(false)

    }
    else if(formValues.date===''){
      setFormError((errors)=>({...errors,date:`la data dell'evento é obbligatoria`}))
      SetFormValidation(false)

    }
    else if(formValues.images.length===0){
      setFormError((errors)=>({...errors,images:`Non é stata inserita nessuna locandina o Immagine`}))
      SetFormValidation(false)

    }
    else{
      SetFormValidation(true)
    }
  }
  const sendData=async()=>{
    setButtonCircle(true)
    SetFormValidation(false)
    let form=new FormData()
    form.append('title',formValues.title);
   form.append('description',formValues.description);
   form.append('date',formValues.date);
   formValues.images.map(file=> form.append('images',file))  
   const config={
    headers:{
      'content-type': 'multipart/form-data'
    }
   } 
    axios.post('/insertevent',
   form,config
    )
    .then(res=>{
    
      if(res.data.status==='accepted')
     { setSuccesAlert(true)
      setButtonCircle(false)

      setFormValues({title:'',description:'',date:'',images:[]})}
      })
      .then(()=>{getAllEvents()})
    .catch(err=>console.log(err))
    }
    
useEffect(()=>{

  if(formValidation===true && Object.values(formError).length===0){

   sendData()
  }
})

const getAllEvents=()=>{
  axios.post('/getallevents')
  .then(res=>{
    if(res.data.status==='accepted'){ setEvenTable(res.data.data)}
  })
}
useEffect(()=>{

 getAllEvents()
},[])
const erase=(id)=>{
  const tempEvents=eventTable
  const tempErasedEvents=erasedEvents
  tempErasedEvents.push(id)
  let newTemp=tempEvents.filter(x=> x.id!=id)
  setErasedEvents(tempErasedEvents)
 setModTable(true)
  setEvenTable(newTemp)


}

const eraseEvents=(e)=>{
  e.preventDefault()
 axios.post('/eraseevents',erasedEvents)
 .then((res)=>{
  if(res.data.status==='accepted'){
    setModTable(false)
 }
 })
 .catch(err=>{})
}

const resetEvents=(e)=>{
  e.preventDefault()
  setModTable(false)
  setErasedEvents([])
  getAllEvents()

}
  return(
    <div className="flex flex-col  items-center justify-start  py-10 h-full min-h-[300px] gap-0">
 
  <div className="w-fit flex flex-col     items-center  gap-3 rounded-md bg-white  min-h-[400px] p-3 h-fit shadow-md shadow-gray-400" >
       
        <h1 className="mb-1 ">Tabella Eventi</h1>
        <div className="p-3  grid  grid-cols-5   w-full gap-5  border rounded-md m-2"> 
       <div className="flex flex-row flex-wrap items-center justify-center" >
       <h1 className="font-black">Titolo</h1>

       </div>
       <div className="flex flex-row flex-wrap items-center justify-center" >
       <h1 className="font-black">Descrizione</h1>

       </div>
       <div className="flex flex-row flex-wrap items-center justify-center" >
       <h1 className="font-black ">Immagini</h1>

       </div>
       <div className="flex flex-row flex-wrap items-center justify-center" >
       <h1 className="font-black ">Data</h1>

       </div>
       <div className="flex flex-row flex-wrap items-center justify-center" >
       <h1 className="font-black ">Elimina</h1>

       </div>


        </div>
       {eventTable.map((x)=>
      
      <div className=" font-normal  grid  grid-cols-5  w-full rounded-md  border-b p-2 "> 
       <div className=" flex overflow-hidden flex-row flex-wrap items-center justify-center" >
       <h1 className="font-sm m-2  font-black ">{x.title}</h1>

       </div>
       <div className="overflow-scroll flex flex-row flex-wrap items-center justify-center" >
       <p className="">{x.description}</p>

        </div>
        <div className="overflow-scroll flex flex-row flex-wrap items-center justify-center" >
        {x.path.map(y=> <img className="w-10 h-10" src={y}></img>)}
        </div>
        <div className="overflow-scroll flex flex-row flex-wrap items-center justify-center" >
        <h1 className="  font-black ">{x.date.slice(0,10).split('-').reverse().join('-')}</h1>

        </div>
        <div className="overflow-scroll flex flex-row flex-wrap items-center justify-center" >
        <img src={closeiconred} className='  w-6 h-6 hover:bg-black/50 rounded-full p-1 cursor-pointer' onClick={()=>erase(x.id)} />

        </div>
      
             

        
      

      
      </div>

     )  }
     <div className="flex flex-row gap-3">
     <button className="flex flex-row justify-center shadow-md w-fit shadow-gray rounded-md bg-blue-500 text-white font-bold  py-3 px-4  hover:bg-blue-800 disabled:bg-gray-500 " disabled={modTable?false:true} onClick={(e)=>eraseEvents(e)} >Salva modifiche</button>
     <button className="flex flex-row justify-center shadow-md w-fit shadow-gray rounded-md bg-red-600 text-white font-bold  py-3 px-4 hover:bg-red-800 disabled:bg-gray-500 " disabled={modTable?false:true} onClick={(e)=>resetEvents(e)}>Annulla </button>

     </div>
    
       </div>

  

   <div className="m-10 items-center flex flex-col gap-10  rounded-md bg-white w-fit min-w-[500px] max-w-[calc(100%-20%] p-5 h-fit shadow-gray-400 shadow-md">
   <h1 className="mb-5 ">Aggiungi Evento</h1>
   <div className="w-[80%]  grid grid-cols-2 gap-0 ">

<label for='title' >Titolo</label>

<input name='title' type='text' className="bg-slate-200 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-[150px]  text-black pr-3" onChange={(e)=>setItem((e))} />
{formError.title && <h1 className="text-center absolute mt-6 font-xs ml-20  w-fit text-red-500 ">{formError.title}</h1>}

</div>
   <div className="w-[80%] grid grid-cols-2 ">

    <label for='description' >Descrizione</label>
   <input name='description'  type='text' className="bg-slate-200 rounded-md border-2 border-transparent focus:outline    outline-blue-800  w-fit min-w-[200px] text-black pr-3" onChange={(e)=>setItem((e))} />
    
    </div>
    <div className="w-[80%]  grid grid-cols-2 ">
    <label for='date' >Data</label>

    <input name='date' type='date' className="bg-slate-200 rounded-md border-2 border-transparent focus:outline    outline-blue-800 w-[150px] text-black pr-3" onChange={(e)=>setItem((e))} />
    {formError.date && <h1 className="text-center absolute mt-6 ml-20 font-xs w-fit p-0 text-red-500">{formError.date}</h1>}

      </div> 
      <div className="w-[80%]  grid grid-cols-2">

       
    <label for='files' >Immagini</label>

<input name='images' type='file' multiple  className=" cursor-pointer file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-blue-700
                hover:file:bg-violet-100 text-black w-fit max-w-[100%]" onChange={(e)=>imageHandleChange((e))} />
{formError.images && <h1 className="text-center absolute mt-10 p-0 m-0 ml-20  font-sm w-fit text-red-500">{formError.images}</h1>}


      </div>
   
      {formValues.images.length>0 && <div className='overflow-scroll flex flex-row gap-2 w-fit max-w-[700px] h-fit p-2 justify-start items-center'>{  Array.from(formValues.images).map((image, index) => <div className='w-fit flex flex-col items-center justify-center'> <img id='images' src={URL.createObjectURL(image)}  className='w-fit h-fit max-w-[150px]' alt='' /><img src={trashicon} className='w-6 h-6  rounded-full cursor-pointer  hover:bg-black/50 p-1' onClick={() => handleDelete(index)}/></div> 
 )}</div>} 
 <div className="w-[100%]  flex flex-col justify-center items-center">
{buttonCircle && <button className="flex flex-row justify-center shadow-md w-[200px] shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800"  > <img src={loadicon} className="animate-spin h-5 w-5 mr-3  " viewBox="0 0 24 24" /></button>}
{!buttonCircle && <button className="flex flex-row justify-center shadow-md w-[200px] shadow-gray rounded-md bg-blue-500 text-white font-bold px-3 p-3  hover:bg-blue-800"  onClick={(e)=>validate(e)}> Inserisci Evento</button>}


</div>
   </div>

   <div>
   {succesAlert && <h1 className="text-center font-sm w-full font-black ">Evento inserito Correttamente!</h1>}


   </div>
   </div>
   
  )
 }
export {AdminPanel, Adminlogin};