import React, { useEffect, useState } from "react";

import winehero from './../static/winesbackground.jpg'
import  salottoimage from './../static/gallery/4.jpg' 

import loadicon from './../static/loadicon.svg'


import { IlSalottoEnglish,IlSalottoItalian } from "../components/LanguageTexts";
import {Helmet} from "react-helmet";


import Navbar from "../components/navbar";
import axios from "axios";






const Wines=(props)=>{
  const[image,setImage]=useState(false)
  const [texts,setTexts]=useState(IlSalottoItalian)
  const[confirmDown,setConfirmDown]=useState(false)

   const width=window.screen.width
  
  useEffect(()=>{
    if(props.language==='ita'){setTexts(IlSalottoItalian)}
    else if(props.language==='eng'){setTexts(IlSalottoEnglish)}

  })
  const getFile=(e)=>{
    e.preventDefault()
    setConfirmDown(true)
    axios({
      url: '/static/files/Ilbargello-vini.pdf', //your url
      method: 'GET',
      responseType: 'blob', // important
  }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Ilbargello-vini.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  }).then(()=>  setConfirmDown(false)
  .catch(err=>console.log(err))
  )
  }
  
return(
    
    <div className="h-fit w-full bg-[#F1EAED] pb-10 items-center overflow-hidden Lora"  >
     <Helmet>
                <meta charSet="utf-8" />
                <title>Il Salotto del Vino | Enoteca in centro a Siena | Osteria il Bargello </title>
                <link rel="canonical" href="https://osteriailbargello.it/vini" />
                <meta name="description" content="Più di una semplice enoteca, un vero e proprio museo del vino nel centro storico di Siena, dove la tua scelta è guidata da cultura, esperienza e passione." />
            </Helmet>
     <div className="h-[400px] sm:h-[400px] w-[100%] overflow-hidden flex items-center justify-center "   >
     <Navbar language={props.language} setLang={props.setLang} />

              <img title="Il salotto del vino enoteca centro storico siena" alt="Il salotto del vino è l’enoteca dell’osteria il bargello e si trova nel centro storico di Siena. Una scelta di oltre 1500 vini che possono essere degustati in osteria o acquistati per un’occasione speciale o per rendere unico un momento." className="min-w-[750px]  brightness-10" src={winehero} />
       <div className="absolute flex flex-col w-full h-fit items-center justify-center gap-5 mt-20">
       <h1 className="text-[#F1EAED]    md:text-6xl text-5xl text-center Vidaloka">{texts.Herotitle}</h1>
       <h1 className="text-[#F1EAED] md:text-xl text-md text-center ">{texts.HeroParagraph}</h1>

       </div>
       

     </div>
     <div className="  sm:mx-5  h-fit pt-5 mt-10  flex flex-col lg:flex-row  text-center md:text-start px-5 items-center justify-center"  >
       <div className="  flex flex-col gap-3 lg:max-w-[40%] w-[100%] justify-center" >
        <h2 className="text-black md:text-3xl text-2xl Lora  ">{texts.titleSalotto}</h2>
        <h2 className="text-blacktext-lg md:text-xl  mb-2">{texts.paragraphSalotto}</h2>
        
        </div>  
        <div className="w-[450px] flex justify-center items-center m-5" >
               <img src={salottoimage} title="Il salotto del vino enoteca Siena" alt="Al salotto del vino, oltre alle intramontabili etichette DOC e DOCG è possibile trovare un’ampia selezione di vini super tuscan che possono essere degustati in accompagnamento alle pietanze dell’osteria il bargello o acquistati per un’occasione particolare." className='w-[100%] max-w-[450px]' />
       
        </div>
       
         
       </div>
       

       <div className="xl:mx-36 mx-5 h-fit pt-5 mt-10   flex flex-col-reverse gap-y-5 md:flex-row  text-start justify-center items-center border-t-2 border-dotted border-gray-300" id="cartavini"  >
       <div className=" px-5 flex flex-col  w-[100%] h-fit justify-center items-center ">
      <div  className=" w-[350px] h-[250px] overflow-hidden flex flex-col justify-center  items-center border-2 rounded-md border-[#7C2523] cursor-pointer" onMouseLeave={()=>setImage(false)} onMouseEnter={()=>setImage(true)}>
     {(image || width<668 ) &&  <img  className="z-[0] brightness-75 w-full h-full" src={winehero}  id="imagehov" /> }      
    {!confirmDown && <button  className="absolute w-[250px]  uppercase  text-md text-white hover:text-white p-3 bg-[#7C2523] hover:outline outline-2 outline-white" onClick={(e)=>getFile(e)}>Scarica la carta</button>}

    
  {confirmDown && <button  className="absolute w-[250px]  uppercase  text-md text-blackhover:text-[#0e0d0a] p-3 bg-[#7C2523] hover:outline outline-2 outline-[#0e0d0a] flex flex-col items-center justify-center" onClick={(e)=>getFile(e)} disabled><img className="animate-spin h-5 w-5 mr-3 ..." src={loadicon} /></button>}
   
   
   </div>
  
    
       </div>
       <div className="sm:px-5 flex flex-col gap-3  m-5 justify-center items-start md:text-start max-w-[550px]" >
        <h2 className="text-black text-3xl Lora  mb-2">{texts.winecartTitle}</h2>
        
        <p className="text-black">{texts.winecartParagraph} </p>
        
        </div>  

       </div>

    
       </div>
       

    
)
};

export default Wines;
