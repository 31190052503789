import React, { useEffect,useState } from "react";
import img1 from './../static/hero1.jpg'
import img2 from './../static/hero2.jpg'
import img3 from './../static/hero3.jpg'
import img4 from './../static/hero4.jpg'
import img5 from './../static/wineback.jpg'
import img6 from './../static/gallery/1.jpg'
import img7 from './../static/gallery/2.jpg'
import img8 from './../static/gallery/3.jpg'
import img9 from './../static/gallery/4.jpg'
import img10 from './../static/gallery/5.jpg'
import img11 from './../static/gallery/6.jpg'
import img12 from './../static/gallery/7.jpg'
import img13 from './../static/gallery/8.jpg'
import img14 from './../static/gallery/17.jpg'
import img15 from './../static/gallery/9.jpg'
import img16 from './../static/gallery/10.jpg'
import img17 from './../static/gallery/11.jpg'
import img18 from './../static/gallery/12.jpg'
import img19 from './../static/gallery/13.jpg'
import img20 from './../static/gallery/14.jpg'
import img21 from './../static/gallery/15.jpg'
import img22 from './../static/gallery/16.jpg'

import closeicon from './../static/closeicon.png'
import rightarrow from './../static/rightarrow.png'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const PhotoGallery=()=>{
  const[images,setImages]=useState([img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22])
  const[scroll,setScroll]=useState(0)
 const[bigImage,setBigImage]=useState(undefined)
const[totalWidth,setTotalWidth]=useState()
 const [scrollCoeff,setScrollCoeff]=useState()

 const navigate=useNavigate()

  const bottomScroll=()=>{
    {scroll<=0?setScroll(totalWidth):setScroll(scroll-scrollCoeff)}
  }

  const upScroll=()=>{
    {scroll>=totalWidth?setScroll(0):setScroll(scroll+scrollCoeff)}


  
  

  }
  useEffect(()=>{
   
    const gallery= document.getElementById('gallery')
    
     gallery.scrollTo({
        left:scroll,
        behavior:'smooth'
     })
    setTotalWidth(document.getElementById('gallery').scrollWidth)
    setScrollCoeff(document.getElementById('gallery').scrollWidth/images.length)
    
  })

  const upBigImage=()=>{
    let index=images.indexOf(bigImage)

    if((index+1)<images.length){
      setBigImage(images[index+1])
    }
  }
  const minBigImage=()=>{
    let index=images.indexOf(bigImage)

    if((index-1)>0){
      setBigImage(images[index-1])
    }
  }
  return(

        <div className="Lora text-black w-full h-full bg-[#F1EAED] flex flex-col items-center justify-start p-10 gap-10">
          <Helmet>
                   <meta charSet="utf-8" />
                <title>Galleria Foto | Wine Bar Osteria il Bargello Siena</title>
                <link rel="canonical" href="https://osteriailbargello.it/gallery" />
                <meta name="description" content="Le foto dei nostri piatti: tartare, pici, cinghiale, salumi toscani e tante altre specialità nel centro di Siena, tra Piazza del Campo e il Duomo." />
            </Helmet>          


      <div className="absolute top-3 left-3">
          <h1 className="Lora font-bold text-md md:text-xl hover:underline cursor-pointer text-black " onClick={()=>navigate('/')}>
            Home
          </h1>
          </div>
        
   
    <img src={rightarrow} onClick={()=>{bottomScroll()}} className=" hover:w-[45px] cursor-pointer w-[40px] h-[100px] md:h-[150px] top-[30%]  rotate-180 absolute left-1 " />

    <div   className="my-20   w-full flex flex-row h-fit gap-10  justify-start items-center flex-nowrap overflow-x-hidden" id="gallery" style={{border:'1px red'}}>
        {images.map((x)=>
        
            <img src={x}  className="w-[355px] md:w[450px] cursor-pointer"  onClick={()=>{setBigImage(x)}} ></img>
        )}


    </div>
    <img src={rightarrow}  className="hover:w-[45px] w-[40px] h-[100px] md:h-[150px]   absolute right-1 top-[30%]" onClick={()=>{upScroll()}} />

    <div className="flex flex-row gap-1 items-center justify-center mx-20">
    {images.slice(images.length/2).map((x,index)=>
    <button className={scroll>=(scrollCoeff*(index*2))?"rounded-full w-4 bg-black h-4 ":"rounded-full w-3 bg-white h-3 brightness-50"  } onClick={()=>{setScroll(scrollCoeff*(index*2))}} />


    )}   
         
    </div>
  {bigImage &&  <div className="fixed bg-black top-0 w-screen h-screen flex flex-col items-center justify-center"  >
  <img src={closeicon} onClick={()=>{setBigImage(undefined)}} className="fixed invert hover:w-[45px] cursor-pointer w-[30px]  rotate-180 top-2 right-2 " />

  <img src={rightarrow} onClick={()=>{minBigImage()}} className="fixed invert hover:w-[45px] cursor-pointer w-[40px] h-[100px] md:h-[150px] top-[47%]  rotate-180  left-1 " />

        <img src={bigImage} className="max-w-[90%]" />
        <img src={rightarrow}  className="fixed cursor-pointer invert hover:w-[45px] w-[40px] h-[100px] md:h-[150px]   right-1 top-[47%]" onClick={()=>{upBigImage()}} />

    </div>}
        </div>
    )
}



export default PhotoGallery;
