import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Heroevents from './../static/gallery/7.jpg'

import {Helmet} from "react-helmet";
import { EventsItalian,EventsEnglish } from "../components/LanguageTexts";
import axios from "axios";

const  Events=(props)=>{
  const [bigImage,setBigImage]=useState()
  const [events,SetEvents]=useState([])
  const [texts,setTexts]=useState(EventsItalian)

 const getEvents=()=>{
  axios.post('/getallevents')
  .then(res=>{
    
    
    SetEvents(res.data.data)})
  .then(()=>console.log(events))
  .catch(err=>{})
 }

  useEffect(()=>{
    getEvents()
  },[])
  useEffect(()=>{
    if(props.language==='ita'){setTexts(EventsItalian)}
    else if(props.language==='eng'){setTexts(EventsEnglish)}

  })
  

    return(
        
    <div className="h-fit  w-full bg-[#F1EAED]  items-center Lora" >
     <Helmet>
                <meta charSet="utf-8" />
                <title>Eventi in centro a Siena | Vino e cucina | Osteria il Bargello </title>
                <link rel="canonical" href="https://osteriailbargello.it/eventi" />
                <meta name="description" content="Apericena, cene, degustazioni: tutti i nostri eventi hanno come protagonisti il buon cibo e il buon vino, da gustare a due passi da Piazza del Campo." />
            </Helmet>
    <div className="h-[300px] w-[100%] overflow-hidden flex items-start justify-center top-0"   >
    <Navbar language={props.language} setLang={props.setLang} />

      <img title="Eventi Wine Bar Osteria Il Bargello" alt="L’osteria il Bargello organizza cene, degustazioni, apericena e altri eventi che hanno in comune il buon cibo, il buon vino e uno scenario unico nel centro storico di siena, vicino al duomo e piazza del campo." className="w-full brightness-50 top-0" src={Heroevents} />
      <div className="absolute flex flex-col w-full h-fit  items-center justify-end gap-5 mt-20" >
      <h1 className="text-[#F1EAED] Vidaloka  md:text-6xl text-5xl  text-center mt-20"> {texts.title}</h1>
      </div>
      

    </div>
   
    <div className="  h-fit py-10 px-5 gap-10 w-full flex flex-col items-center" >
           
    <p className="text-black  md:max-w-[70%] min-h-[300px]  md:text-xl text-md text-center Lora">{texts.paragraph} </p>

{ events.length>0 && <div className="w-[90%] h-fit flex flex-col items-center justify-start gap-10">
     {events.map((x)=>
     <div className="flex flex-col w-full pb-3 gap-4 mb-10 border-b-2 border-black items-center justify-center  text-black"> 
      <div className="flex flex-col items-center justify-center"> 
      <h3 className=" text-3xl  capitalize Lora">{x.title}</h3>
      <h3 className=" text-2xl  capitalize Lora">{x.date.slice(0,10).split('-').reverse().join('-')}</h3>

      </div>
      <p lassName="text-2xl text-center Lora" >{x.description}</p>
      <div className="w-[90%] h-fit mx-5  flex flex-row gap-2 overflow-scroll  justify-start"> 

      {x.path.map(x=>
       <img src={x} onClick={()=>setBigImage(x)} className="max-w-[350px] w-full cursor-pointer" />
      
       
        )}
        </div>
       
     </div>
     
     
     )}


    {bigImage!=undefined? <div onClick={()=>setBigImage(undefined)} className="bg-black/40 flex flex-col top-0 items-center justify-center  fixed w-screen h-screen z-99 p-5 " style={{backgroundColor:'black'}}>
    
    <img className=" fixed  max-h-[90%] " src={bigImage} />


    </div> :null}  

     </div>}
  
  
  </div>
        
        </div>


    )
}



export default Events;
