import React, { useEffect, useState } from "react";


import imageHero1 from './../static/hero1.jpg'
import imageHero2 from './../static/hero2.jpg'
import imageHero3 from './../static/hero3.jpg'
import imageHero4 from './../static/hero4.jpg'

import logo from'./../static/logo.png'

import chef from './../static/chef.jpeg'
import chef2 from './../static/chef2.jpg'
import divider from './../static/divider.png'
import lineicon from './../static/lineicon.png'

import facebookicon from './../static/facebookicon.png'
import instagramicon from './../static/instagramicon.png'

import menu1 from './../static/menu1.jpg'
import menu2 from './../static/menu2.jpg'
import menu3 from './../static/menu3.jpg'
import tagliere from './../static/tagliere.jpg'




import wineback from './../static/wineback.jpg'
import { HomeItalian,HomeEnglish } from "./LanguageTexts";
import Navbar from "./navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Hero1=(props)=>{
 
   const [texts,setTexts]=useState(HomeItalian)
   const[hero,setHero]=useState(1)
   useEffect(()=>{
   const timeout= setTimeout(()=>{
       {hero===4?setHero(1):setHero(hero+1)}
    },8000)
   return ()=>{clearTimeout(timeout)}
   })

   useEffect(()=>{
      if(props.language==='ita'){setTexts(HomeItalian)}
      else if(props.language==='eng'){setTexts(HomeEnglish)}
  
    })
    return(
        <div className="Lora h-screen w-screen  bg-[#F1EAED]">
     <Navbar language={props.language} setLang={props.setLang}/>
       { hero===1 && <div className="overflow-hidden  h-screen w-screen ">       
       
          
          <div className="bgimage brightness-50 overflow-hidden h-[100%] w-screen  "  style={{backgroundImage:`Url(${imageHero1})`}} />

         
            <div className="flex flex-col items-center  justify-center overflow-hidden h-full w-full  text-animation absolute top-0 gap-y-5 text-center px-5" >
            <img  className="  w-[28%] min-w-[250px] invert mt-12" src={logo} />
                   <h1 className="Vidaloka text-[#F1EAED]   md:text-6xl text-5xl   "> {texts.hero1.title}</h1>

                   <p  className="Lora text-[#F1EAED]   md:text-xl text-md max-w-[600px]">{texts.hero1.paragraph}</p>
                  
          </div>  </div> }
  
       
         {hero===2 && <div className="overflow-hidden   h-screen w-screen ">    
            <div className="bgimage overflow-hidden h-full w-full   brightness-50"  style={{backgroundImage:`Url(${imageHero2})`}} />

            
          
            <div className="text-[#F1EAED] flex flex-col items-center justify-center w-[100%] h-full absolute top-0  text-animation gap-y-5 text-center px-5">
            <img  className="  w-[28%] min-w-[250px] invert mt-12" src={logo} />


          <h1 className="Vidaloka text-[#F1EAED]   md:text-6xl text-5xl  "> {texts.hero1.title}</h1>
                   <p  className="Lora text-[#F1EAED]   md:text-xl text-md max-w-[600px]">{texts.hero1.paragraph2}</p>
              </div>

         </div> }
         {hero===3 && <div className="overflow-hidden   h-screen w-screen ">    
            <div className="bgimage overflow-hidden h-full w-full   brightness-50"  style={{backgroundImage:`Url(${imageHero3})`}} />

            
          
            <div className="flex flex-col items-center justify-center w-[100%] h-full absolute top-0  text-animation gap-y-5 text-center px-5">
            <img  className="  w-[28%] min-w-[250px] invert mt-12" src={logo} />


          <h1 className="Vidaloka text-[#F1EAED]    md:text-6xl text-5xl  "> {texts.hero1.title}</h1>
                   <p  className="Lora text-[#F1EAED]   md:text-xl text-md max-w-[600px]">{texts.hero1.paragraph2}</p>
              </div>

         </div> }   
         {hero===4 && <div className="overflow-hidden  h-screen w-screen ">    
            <div className="bgimage overflow-hidden h-full w-full   brightness-50"  style={{backgroundImage:`Url(${imageHero4})`}} />

            
          
            <div className="flex flex-col items-center justify-center w-[100%] h-full absolute top-0  text-animation gap-y-5 text-center px-5">
            <img  className="  w-[28%] min-w-[250px] invert mt-12" src={logo} />


          <h1 className="Vidaloka text-[#F1EAED]  md:text-6xl text-5xl  "> {texts.hero1.title}</h1>
                   <p  className="Lora text-[#F1EAED]  md:text-xl text-md max-w-[600px]">{texts.hero1.paragraph2}</p>
              </div>

         </div> }
            
       
        <div className="flex flex-row items-center justify-center gap-5 h-10 w-[100%] bg-transparent absolute bottom-3 " >
      
        <button className={hero===1?"rounded-full w-5 bg-white h-5 ":"rounded-full w-4 bg-white h-4 brightness-50"  } onClick={()=>{setHero(1)}} />
         <button className={hero===2?"rounded-full w-5 bg-white h-5 ":"rounded-full w-4 bg-white h-4 brightness-50" } onClick={()=>{setHero(2)}} />   
         <button className={hero===3?"rounded-full w-5 bg-white h-5 ":"rounded-full w-4 bg-white h-4 brightness-50" }  onClick={()=>{setHero(3)}} />  
            <button className={hero===4?"rounded-full w-5 bg-white h-5 ":"rounded-full w-4 bg-white h-4 brightness-50" }  onClick={()=>{setHero(4)}} />   
         
         
      



        </div>
        
        </div>
    )
}



const Hero2=(props)=>{
   const [texts,setTexts]=useState(HomeItalian)
   
   useEffect(()=>{
      if(props.language==='ita'){setTexts(HomeItalian)}
      else if(props.language==='eng'){setTexts(HomeEnglish)}
  
    })
   return(
      <div className="Lora py-7 w-[100%]  h-fit bg-white  grid grid-cols-2 grid-rows-1 sm:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 text-slate-300" id="chisiamo">
          <div className=" col-start-1 col-end-2 row-span-1 lg:col-start-1 md:col-end-1 lg:row-span-1 flex flex-col items-center justify-center fromUnder"><img title="Francesco e Tommaso Osteria Il Bargello Siena" alt="Prima che soci, grandi amici: Francesco e Tommaso, i fondatori dell’Osteria il Bargello, nel centro storico di Siena." src={chef} className='p-1 rounded-md w-[90%] sm:max-w-[300px]' />      </div>
         <div className=" col-start-2 col-end-3 row-span-1 lg:col-start-3 md:col-end-3 lg:row-span-1 flex flex-col items-center justify-center fromUnder"><img title="Calice e bottiglia di vino Osteria Il Bargello Siena" alt="L’osteria il bargello a Siena propone un’ampia scelta di vini di ottima qualità, acquistabili sia al calice che in bottiglia e da consumare in osteria o dove preferisci." src={chef2} className='p-1 rounded-md w-[90%] sm:max-w-[300px]' />  </div>


         <div className="fromUnder sm:mt-5  lg:mt-0 col-start-1 col-end-3 row-span-2 p-3  lg:col-start-2  lg:col-end-2 lg:row-start-1 flex flex-col items-center justify-center" >
       
         <h2 className="text-[#601229] text-2xl pb-2   text-center  " >{texts.chisiamo.title}</h2>
         <p className="px-3 pb-5 text-center  text-black" > {texts.chisiamo.paragraph}</p>
<img src={divider} className='w-[150px] '></img>
<h2 className="text-[#601229] pb-2   text-center  text-2xl" > {texts.filosofia.title}
</h2>
         <p className="text-center px-3  text-black" > {texts.filosofia.paragraph}</p>
              </div>
     </div>


   )
}



const Hero3=(props)=>{
   const [texts,setTexts]=useState(HomeItalian)
   const[img,setImg]=useState(1)
   const navigate=useNavigate()
  useEffect(()=>{
   if(props.language==='ita'){setTexts(HomeItalian)}
   else if(props.language==='eng'){setTexts(HomeEnglish)}

 })
 useEffect(()=>{
   const timeout= setTimeout(()=>{
       {img===4?setImg(1):setImg(img+1)}
    },4000)
   return ()=>{clearTimeout(timeout)}
   })

  
  
   return(
      <div className=" bg-[#F1EAED]   Lora w-screen flex flex-col h-fit py-7" id="menu" >
        
      <div className="my-20 md:mx-0 flex flex-col items-center h-fit justify-start col-start-1 row-start-1 row-end-1  ">
     
      <div className="w-full   h-full  flex flex-col xl:flex-row my-5 " >
      <div className="w-full flex flex-col items-center justify-center  mb-5 " > 
      <p className="text-black text-2xl text-center px-4 w-[400px] ">{texts.menu.paragraph}</p>
     </div>
     <div className="xl:border-2 xl:mx-5 border-white/5 drop-shadow-black  drop-shadow-xl w-full h-[350px] lg:h-[550px] xl:ml-10 flex flex-col items-center justify-center md:rounded-md" >
      {img==1 &&  <img title="Spezzatino di Cinghiale Wine Bar Osteria Il Bargello Siena" alt="Un piatto tipico della tradizione toscana, lo spezzatino di cinghiale, presente tra i secondi dell’osteria il bargello, cotto in umido con alloro e pepe." className="md:w-[650px] w-[450px] m-2   slideimganimation slideimganimation2" src={menu1} />}
      {img==2 &&  <img alt="Tagliere di salumi toscani, uno degli antipasti proposti dall’Osteria il Bargello, perfetto anche per godersi un aperitivo nel centro storico di Siena." title='Tagliere di salumi Wine Bar Osteria Il Bargello Siena' className="md:w-[650px] w-[450px] m-2   slideimganimation slideimganimation2" src={tagliere} />}
      {img==3 &&  <img title="Maialino Wine Bar Osteria Il Bargello Siena"  alt="Pici all’aglione, la ricetta dell’osteria il bargello: pici tirati a mano cucinati con aglione della Valdichiana e salsa di pomodoro" className="md:w-[650px] w-[450px] m-2   slideimganimation slideimganimation2" src={menu2} />}
      {img==4 &&  <img title="Pici all’aglione Wine Bar Osteria Il Bargello Siena" alt=" Un piatto tipico della tradizione toscana, lo spezzatino di cinghiale, presente tra i secondi dell’osteria il bargello, cotto in umido con alloro e pepe." className="md:w-[650px] w-[450px] m-2 slideimganimation slideimganimation2" src={menu3} />}

        
      </div> 
      
      
           </div>

           <div className="mx-10 flex flex-row items-center justify-center ">
            <button onClick={()=>navigate('/menu')} className="rounded  p-3  bg-[#601229] text-white border  border-black hover:bg-black  ">
               {texts.menu.button}
            </button>
          
           </div>

      </div>
      
      
      </div>
   )
}


const Hero4=(props)=>{
   const [texts,setTexts]=useState(HomeItalian)
   const navigate=useNavigate()
   const getFile=(e)=>{
      e.preventDefault()
     
      axios({
        url: '/static/files/Ilbargello-vini.pdf', //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Ilbargello-vini.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);})
    }
   useEffect(()=>{
      if(props.language==='ita'){setTexts(HomeItalian)}
      else if(props.language==='eng'){setTexts(HomeEnglish)}
  
    })
  
   return(
      <div className="bg-[#F1EAED]  Lora h-fit py-10 w-screen flex flex-col " id="vini" >
         <div className="flex flex-row gap-3 items-center justify-center"  >
      <img className="max-w-[100px] px-2" src={lineicon} />
      <h2 className="text-black pb-2   text-center  text-2xl" >{texts.salottodelvino.titleH2}</h2>
      <img className="max-w-[100px] px-2" src={lineicon} />

      </div>
       <div className="overflow-hidden w-full px-5 md:px-20 h-[100px] md:h-[200px] flex justify-center items-center " >
       <img className="w-max h-max  pt-[50px]   " title="Il salotto del vino enoteca centro storico siena" alt="Il salotto del vino è l’enoteca dell’osteria il bargello e si trova nel centro storico di Siena. Una scelta di oltre 1500 vini che possono essere degustati in osteria o acquistati per un’occasione speciale o per rendere unico un momento." src={wineback}   />

       </div>
       <div className="h-fit md:px-10 py-5 z-10" >
       
      <div className="w-[100%] flex flex-col items-center justify-center px-5 md:px-10 py-5" > 
      <h3 className="text-black  text-lg text-center  " >{texts.salottodelvino.titleH3}</h3>
      <p className="text-black  text-md text-center w-[350px] md:w-[450px]" >{texts.salottodelvino.paragraph}</p>

     </div>
     <div className="flex flex-col sm:flex-row items-center justify-center" >
      
     <div className="mx-10 h-full flex flex-col sm:flex-row items-center  justify-center gap-5  my-5 ">
      
     <button onClick={(e)=>getFile(e)} className="rounded  p-2  bg-[#601229] text-white border  border-black hover:bg-black   ">
            La carta dei vini 
            </button>
         

            <button onClick={()=>navigate('/vini')}  className="rounded  p-2  bg-[#601229] text-white border  border-black hover:bg-black   ">
               Il salotto del vino
            </button> 
           </div>
     </div>
     </div>
     </div>

     
   )
}



const Hero5=()=>{

   
  
   return(
      <div className="bg-white  Lora p-5 border-t border-dotted  w-screen h-fit flex  justify-center items-start text-center"  >
      <div className="px-5 w-fit h-full pb-2  flex flex-row flex-wrap gap-10 md:gap-12 lg:gap-15 justify-center items-start ">
         <div className="flex flex-col  ">
            
            <h1 className="text-black text-xs md:text-sm uppercase pb-3  ">Indirizzo</h1>
            <h1 className="text-black text-xs md:text-sm">Via di Città, 55 - Siena</h1>
           
   </div>
          
            <div className="flex flex-col ">

            <h1 className="text-black text-xs md:text-sm uppercase pb-3">Prenota</h1>
            <h1 className="text-black text-xs md:text-sm">0577 045649</h1>
            <h1 className="text-black text-xs md:text-sm">info@ilbargello55.com</h1>

           

            </div>
            <div className="flex flex-col ">

            <h1 className="text-black text-xs md:text-sm uppercase pb-3">Contatti Aziendali</h1>
            
         <h1 className="text-black text-xs md:text-sm">Bar Osteria il Bargello S.r.l</h1>
         <h1 className="text-black text-xs md:text-sm">P.iva IT01386690521</h1>


            </div>
            <div className="flex flex-col ">

<h1 className="text-black text-xs md:text-sm uppercase pb-3">Orario</h1>

<h1 className="text-black text-xs md:text-sm">Tutti i giorni </h1>
<h1 className="text-black text-xs md:text-sm">12.00 - 22.30</h1>


</div>
            <div className="flex flex-col justify-center  items-center ">

            <h1 className="text-black text-xs md:text-sm uppercase pb-3">Seguici</h1>
               <div className="flex flex-row gap-3">
             <a href="https://www.facebook.com/ilbargello55/" target="_blank"><img className="w-4 h-4 hover:w-5 hover:h-5" src={facebookicon} /></a>  
             <a href='https://www.instagram.com/ilbargello/' target="_blank"><img className="w-4 h-4 hover:w-5 hover:h-5" src={instagramicon}  /></a>  

               </div>

              
            </div>
            <div className="flex flex-col justify-center  items-center ">

<h1 className="text-black text-xs md:text-sm uppercase pb-3"> Policy</h1>
   <div className="flex flex-col gap-1"> 
      <a href="https://www.iubenda.com/privacy-policy/75131889" className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>   
   <a href="https://www.iubenda.com/privacy-policy/75131889/cookie-policy" className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
   </div>

  
</div>

               </div>
   
         </div>
       
        

     
   )
}

export { Hero1 , Hero2 ,Hero3,Hero4,Hero5};
